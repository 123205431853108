import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#e4e4e4'
    // backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // padding: theme.spacing(0, 5),
  },
  itemImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center'
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -80,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/productCurvyLines.png"
          className={classes.curvyLines}
          alt="Beate Eckardt - Naturheilpraxis Hanstedt"
        />
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" marked="center" className={classes.title}>
              Zu meiner Person
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid item xs={12} md={12}>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                  date="2022"
                  iconStyle={{ background: '#f47630', color: '#fff' }}
                  icon={<CheckCircleOutlineIcon />}
                >
                  <Typography variant="h5" >
                    Zertifizierte Bioresonanz-Therapeutin nach Paul Schmidt
                  </Typography>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="seit 2008"
                  iconStyle={{ background: '#f47630', color: '#fff' }}
                  icon={<CheckCircleOutlineIcon />}
                >
                  <Typography variant="h5" >
                    Erweitertes Zertifikat “Medi-Taping”
                  </Typography>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2004 - 2007"
                  iconStyle={{ background: '#f47630', color: '#fff' }}
                  icon={<CheckCircleOutlineIcon />}
                >
                  <Typography variant="h5" >
                    3-jährige Ausbildung in “Traditioneller Chinesischer Medizin”
                  </Typography>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="seit 2004"
                  iconStyle={{ background: '#f47630', color: '#fff' }}
                  icon={<CheckCircleOutlineIcon />}
                >
                  <Typography variant="h5" >
                    Heilpraktikerin
                  </Typography>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  date="seit 1987"
                  iconStyle={{ background: '#f47630', color: '#fff' }}
                  icon={<CheckCircleOutlineIcon />}
                >
                  <Typography variant="h5" >
                    Examinierte Krankenschwester
                  </Typography>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: 60 }}>
            <Badge
              overlap="rectangle"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<Avatar alt="Naturheilpraxis Hanstedt Button" style={{ width: 100, height: 100, marginTop: -80 }} src="/beate-eckardt-naturheilpraxis-hanstedt-button.png" />}

            >
              <Avatar alt="Beate Eckardt" variant="square" style={{ width: '100%', height: '100%', borderRadius: 20 }} src="/beate-eckardt-naturheilpraxis-hanstedt-beate.jpg" />
            </Badge>
            <Typography style={{ paddingTop: 20 }} >
              Mein Name ist Beate Eckardt, ich bin gelernte Krankenschwester und
              arbeite seit 2004 als Heilpraktikerin in Hanstedt, in meiner eigenen Praxis.
              Im Laufe der Jahre habe ich mich auf Akupunktur (trad.chin. Medizin=TCM )
              und der Bioresonanz nach Paul Schmidt spezialisiert, die ich mit viel Erfolg und Begeisterung anwende.
              Ich freue mich über Ihren Anruf, oder schreiben Sie mir eine Mail, um einen Termin zu vereinbaren.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section >
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
