import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React, { useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProductCategories from "./modules/views/ProductCategories";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductHero2 from "./modules/views/ProductHero2";
import ProductHero3 from "./modules/views/ProductHero3";
import ProductValues from "./modules/views/ProductValues";
import ProductValues2 from "./modules/views/ProductValues2";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import ProductCTA from "./modules/views/ProductCTA";
import AppAppBar from "./modules/views/AppAppBar";
import Privacy from "./Privacy";
import Terms from "./Terms";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/datenschutz">
          <Privacy />
        </Route>
        <Route path="/impressum">
          <Terms />
        </Route>
      </Switch>
    </Router>
  );
}

function Index() {
  const titleRef = useRef();

  function handleClick() {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <React.Fragment>
      <AppAppBar titleRef={titleRef} />
      <ProductHero titleRef={titleRef} />
      <ProductValues titleRef={titleRef} />
      <ProductHero2 titleRef={titleRef} />
      <ProductValues2 titleRef={titleRef} />
      <ProductCategories titleRef={titleRef} />
      <ProductHero3 titleRef={titleRef} />
      <ProductCTA titleRef={titleRef} />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(App);
