import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "../components/AppBar";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import { Link } from "react-router-dom";
import { Typography, useMediaQuery } from "@material-ui/core";

const styles = (theme) => ({
  title: {
    color: theme.palette.common.white,

  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: "space-between",
    backgroundColor: "#f47630"
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.common.white,
  },
});

function AppAppBar(props) {
  console.log(props);
  const smallDisplay = useMediaQuery('(min-width:600px)');

  const { classes, titleRef } = props;

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <Link to={"/"}>

              <img
                src="/beate-eckardt-naturheilpraxis-hanstedt-stone.png"
                alt="Beate Eckardt - Naturheilpraxis Hanstedt"
                height="50"

              />
            </Link>

          </div>

          <div className={classes.center}>

            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                style={{ fontSize: smallDisplay ? 24 : 16 }}
              >
                {"Naturheilpraxis Hanstedt"}
              </Typography>
            </Link>
          </div>

          <div className={classes.right}>

            <Link to={"/"} style={{ textDecoration: "none" }} onClick={() => {
              titleRef.current.scrollIntoView({ behavior: "smooth" });
            }}>
              <Typography
                variant="h6"
                underline="none"
                className={clsx(classes.rightLink, classes.linkSecondary)}
                style={{ fontSize: smallDisplay ? 24 : 16 }}
              >
                {"Kontakt"}
              </Typography>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  titleRef: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
