import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(30),
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 700,
  },
});

function ProductCTA(props) {
  const { classes } = props;

  return (
    <Container ref={props.titleRef} className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <div className={classes.cardContent}>
              <Typography variant="h4" component="h2" gutterBottom>
                Kontakt
              </Typography>
              <Typography variant="h5">
                Rufen Sie mich an, oder schicken Sie mir eine E-Mail, zwecks Terminvereinbarung.
              </Typography>
              <hr></hr>
              <Typography variant="h5">
                Beate Eckardt - Naturheilpraxis Hanstedt
              </Typography>
              <Typography variant="h5">
                Harburger Straße 27
              </Typography>
              <Typography variant="h5">
                21271 Hanstedt
              </Typography>
              <Typography variant="h5">
                <PhoneIcon fontSize='small' style={{ paddingTop: 6 }} />04184 892349
              </Typography>
              <Typography variant="h5">
                <MailIcon fontSize='small' style={{ paddingTop: 6 }} />
                <a href='mailto:kontakt@naturheilpraxis-hanstedt.de?subject=Kontaktanfrage'>
                  kontakt@naturheilpraxis-hanstedt.de
                </a>
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src="/beate-eckardt-naturheilpraxis-hanstedt-map.png"
              alt="Beate Eckardt - Naturheilpraxis Hanstedt"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>

    </Container>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);
