import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import { Link } from "react-router-dom";

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href="https://naturheilpraxis-hanstedt.de/">
        Beate Eckardt - Naturheilpraxis Hanstedt
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#e4e4e4',
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5} justify="center" alignItems='center'>
          <Grid item className={classes.icons} sm={4} xs={12}>
            <a href="#" className={classes.icon}>
              <img src="/appFooterFacebook.png" alt="Beate Eckardt - Naturheilpraxis Hanstedt" />
            </a>
            <a href="#" className={classes.icon}>
              <img src="/appFooterTwitter.png" alt="Beate Eckardt - Naturheilpraxis Hanstedt" />
            </a>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Copyright />
          </Grid>
          <Grid item sm={4} xs={12} >

            <Typography >
              <Link to="/impressum" title="IMPRESSUM">
                IMPRESSUM
              </Link>
              {" "}
              <Link to="/impressum" title="DATENSCHUTZ">
                DATENSCHUTZ
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
