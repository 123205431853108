import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout2';
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

const backgroundImage = '/beate-eckardt-naturheilpraxis-hanstedt-bg1.jpg';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
    width: "100vw",
    // height: "100vh",
    // height: `${window.innerHeight}px`,

  },
  button: {
    minWidth: 200,
    textDecor: "none"
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  textIntro: {
    position: "relative",
  },
});

function ProductHero2(props) {
  const { classes } = props;
  const smallDisplay = useMediaQuery('(min-width:600px)');

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <Typography color="inherit" align="center" variant={smallDisplay ? "h4" : "h5"} marked="center">
        “Gesundheit ist zwar nicht alles,<br></br>
        aber ohne Gesundheit ist alles nichts.”
      </Typography>
      <Typography variant="h5" style={{ width: "80%", textAlign: 'right' }} marked="center" component="h6">
        (Arthur Schopenhauer)
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero2);