import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import 'react-vertical-timeline-component/style.min.css';

import BlurOnIcon from '@material-ui/icons/BlurOn';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#e4e4e4',
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // padding: theme.spacing(0, 5),
  },
  itemImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center'
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues2(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/productCurvyLines.png"
          className={classes.curvyLines}
          alt="Beate Eckardt - Naturheilpraxis Hanstedt"
        />
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" marked="center" className={classes.title}>
              Meine Schwerpunkte und Leistungen
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className={classes.itemImage}>
                  <BlurOnIcon fontSize='large' />
                </div>
                <div className={classes.item}>
                  <Typography variant="h6" className={classes.title}>
                    Bioresonanz-Therapie
                  </Typography>
                  <Typography variant="h5">
                    {'Die Bioresonanz-Therapie nach Paul Schmidt kommuniziert mit dem Frequenzmuster in der Sprache der Zelle. Sie geht also mit dem Körper und der Zelle in Resonanz.'}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.itemImage}>
                  <AccessibilityNewIcon fontSize='large' />
                </div>
                <div className={classes.item}>
                  <Typography variant="h6" className={classes.title}>
                    Traditionelle Chinesische Medizin
                  </Typography>
                  <Typography variant="h5" >
                    {'• Akupunktur'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Zungen- und Pulsdiagnose'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Schröpfen / Schröpfmassage'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Gua Sha'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Schädelakupunktur nach Yamamoto'}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.itemImage}>
                  <NaturePeopleIcon fontSize='large' />
                </div>
                <div className={classes.item}>
                  <Typography variant="h6" className={classes.title}>
                    Allgemeine Naturheilverfahren
                  </Typography>
                  <Typography variant="h5">
                    {'• Baunscheidtieren'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Infusionstherapie'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Ohrkerzentherapie'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Darmsanierung'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Bachblütentherapie'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Komplexhomöopathie'}
                  </Typography>
                  <Typography variant="h5">
                    {'• Schüsslersalztherapie'}
                  </Typography>
                </div>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section >
  );
}

ProductValues2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues2);
