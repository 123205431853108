import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

const backgroundImage = '/beate-eckardt-naturheilpraxis-hanstedt-bg1.jpg';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
    width: "100vw",
    // height: "100vh",
    // height: `${window.innerHeight}px`,

  },
  button: {
    marginTop: 200,
    minWidth: 200,
    textDecor: "none",
    backgroundColor: '#f47631',
    color: "white"
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  textIntro: {
    position: "relative",
  },
});

function ProductHero(props) {
  const { classes, titleRef } = props;
  const smallDisplay = useMediaQuery('(min-width:600px)');

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <Typography color="inherit" align="center" variant={smallDisplay ? "h4" : "h6"} marked="center">
        {"Beate Eckardt"}
      </Typography>
      <Typography color="inherit" align="center" variant={smallDisplay ? "h3" : "h4"} marked="center">
        {"Naturheilpraxis Hanstedt"}
      </Typography>
      <Typography color="inherit" align="center" variant="h5" >
        Akupunktur • Trad. Chinesische Medizin • Bioresonanz-Therapie
      </Typography>
      <Button
        variant="contained"
        size="large"
        className={classes.button}
        component="button"
        onClick={() => titleRef.current.scrollIntoView({ behavior: "smooth" })}
      >
        Kontakt
      </Button>

    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
  titleRef: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);