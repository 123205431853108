import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Box } from '@material-ui/core';
const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center'
  },
});

function HandleDialogs(props) {
  switch (props.value) {
    case 0:
      return null
    case 1:
      return <Dialog
        maxWidth="md"
        open={props.value == 1 ? true : false}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            component="h2"
            variant="h4"
            style={{ marginTop: 30 }}
          >
            Bioresonanz-Therapie
          </Typography>
        </DialogTitle>
        <Box style={{ padding: 26 }}>

          <Typography
            component="h3"
            variant="subtitle1"
          >
            Die Bioresonanz-Therapie nach Paul Schmidt kommuniziert mit dem Frequenzmuster in der Sprache der Zelle. Sie geht also mit dem Körper und der Zelle in Resonanz.
            Wir alle kennen es... ist man gut gelaunt, hat man eine andere Ausstrahlung als wenn uns eine „Laus über die Leber gelaufen ist“. Wenn wir krank sind, geben wir andere Schwingungen, Töne und Ausscheidungen von uns. Demnach strahlt der Körper mit unterschiedlichen Schwingungen. Jede einzelne dieser Schwingungen steht miteinander in Verbindung. Dadurch bilden sie gemeinsam ein Gesamtschwingungsspektrum, das wiederum individuell von Person zu Person anders ist. Dennoch lässt sich daran Gesundheit und Krankheit erkennen, sowie das Störungsfeld ausdrücken.
            Dies ist wiederum wichtig für die Diagnose und eine gezielte Therapie. Über die Bioresonanz lassen sich auch Testungen von Medikamenten, allergische Belastungen, Umweltgifte, Unverträglichkeiten testen. Die Bioresonanz ist absolut schmerzfrei und frei von Nebenwirkungen.
          </Typography>
          <Typography
            component="h2"
            variant="subtitle2"
            style={{ marginTop: 30 }}
          >
            Einsatzgebiete u.a.:
          </Typography>
          <List aria-label="Bioresonanz-Therapie">
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Magen- und Darmbeschwerden
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Borrelioseerkennung und /-behandlung
                </Typography>
              </ListItemText>

            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Hauterkrankungen
                </Typography>
              </ListItemText>

            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Allergien
                </Typography>
              </ListItemText>

            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Kinderwunsch
                </Typography>
              </ListItemText>

            </ListItem>
          </List>
        </Box>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            zurück
          </Button>
        </DialogActions>
      </Dialog>
    case 2:
      return <Dialog
        maxWidth="md"
        open={props.value == 2 ? true : false}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            component="h2"
            variant="h4"
            style={{ marginTop: 30 }}
          >
            Akupunktur
          </Typography>
        </DialogTitle>
        <Box style={{ padding: 26 }}>

          <Typography
            component="h3"
            variant="subtitle1"
          >
            Die Akupunktur ist ein Teilgebiet der Traditionellen Chinesischen Medizin (TCM). Sie wirkt bei gestörter Organfunktion heilend – ohne Nebenwirkungen. Zudem wirkt sie auch bei chronischen Schmerzen positiv beeinflussend.
          </Typography>
          <Typography
            component="h2"
            variant="subtitle2"
            style={{ marginTop: 30 }}
          >
            Einsatzgebiete u.a.:
          </Typography>
          <List aria-label="Akupunktur">
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  orthopädische Erkrankungen (Verspannungen, Schulter-Arm-Syndrom)
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Bronchial- und Lungenerkrankungen
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Allergien
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Migräne/Kopfschmerzen
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Herz- und Kreislauferkrankungen
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  neurologische Erkrankungen
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  psychische Erkrankungen
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Magen- und Darmbeschwerden
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Typography
            component="h3"
            variant="subtitle1"
            style={{ marginTop: 30 }}
          >
            Gerade wegen ihres ganzheitlichen Ansatzes ist die Traditionelle Chinesische Medizin eine sinnvolle Ergänzung zur konventionellen westlichen Medizin.
          </Typography>
        </Box>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            zurück
          </Button>
        </DialogActions>
      </Dialog>
    case 3:
      return <Dialog
        maxWidth="md"
        open={props.value == 3 ? true : false}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            component="h2"
            variant="h4"
            style={{ marginTop: 30 }}
          >
            Medi-Taping
          </Typography>
        </DialogTitle>
        <Box style={{ padding: 26 }}>
          <Typography
            component="h3"
            variant="subtitle1"
          >
            Die Medi-Taping-Therapie ist schmerzlos und ohne Nebenwirkungen. Sie beeinflusst die Schmerzfortleitung und aktiviert das körpereigne Schmerzdämpfungssystem. Schmerzen werden gelindert und andere Therapien erfolgreich unterstützt.
            Ein spezielles, elastisches Tape und die besondere Technik machen es möglich, Schmerzen und Bewegungseinschränkungen in vielen Fällen sofort und erfolgreich zu therapieren. Zudem verbessert es die Muskelfunktion, die Muskulatur wird entspannt und durch die Eigenbewegung massiert          </Typography>
          <Typography
            component="h2"
            variant="subtitle2"
            style={{ marginTop: 30 }}
          >
            Einsatzgebiete u.a.:
          </Typography>
          <List aria-label="Medi-Taping">
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  orthopädische Erkrankungen (Rücken-/ Gelenkschmerzen)
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  Kopfschmerzen (Spannungskopfschmerz, Tri- geminusneuralgie)
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  neuropathische Schmerzen (Polyneuropathie, Restless legs, Nervenverletzungen)
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <FiberManualRecordIcon fontSize='smal' />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="h3"
                  variant="subtitle2"
                >
                  muskuläre Schmerzsyndrome (Muskelfaserriss, Folgen eines Schlaganfalles, Multiple Sklerose)
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            zurück
          </Button>
        </DialogActions>
      </Dialog>
  }
};


function ProductCategories(props) {

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);


  const handleClickOpen = (v) => {
    setValue(v)
    setOpen(true);
  };

  const handleClose = () => {
    setValue(0)
    setOpen(false);
  };

  const { classes } = props;

  const images = [
    {
      url: "/beate-eckardt-naturheilpraxis-hanstedt-bioresonanz.jpeg",
      title: "Bioresonanz-Therapie",
    },
    {
      url: "/beate-eckardt-naturheilpraxis-hanstedt-akkupunktur.jpg",
      title: "Akupunktur",
    },
    {
      url: "/beate-eckardt-naturheilpraxis-hanstedt-tape.jpeg",
      title: "Medi-Taping",
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" className={classes.title}>
        Leistungen im Detail
      </Typography>
      <div className={classes.images}>
        <ButtonBase
          onClick={() => handleClickOpen(1)}
          className={classes.imageWrapper}
          style={{
            width: "35%",
          }}
        >
          <div
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${images[0].url})`,
            }}
          />
          <div className={classes.imageBackdrop} />
          <div className={classes.imageButton}>
            <Typography
              component="h3"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {images[0].title}
              <div className={classes.imageMarked} />
            </Typography>
          </div>
        </ButtonBase>
        <ButtonBase
          onClick={() => handleClickOpen(2)}
          className={classes.imageWrapper}
          style={{
            width: "43%",
          }}
        >
          <div
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${images[1].url})`,
            }}
          />
          <div className={classes.imageBackdrop} />
          <div className={classes.imageButton}>
            <Typography
              component="h3"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {images[1].title}
              <div className={classes.imageMarked} />
            </Typography>
          </div>
        </ButtonBase>
        <ButtonBase
          onClick={() => handleClickOpen(3)}
          className={classes.imageWrapper}
          style={{
            width: "22%",
          }}
        >
          <div
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${images[2].url})`,
            }}
          />
          <div className={classes.imageBackdrop} />
          <div className={classes.imageButton}>
            <Typography
              component="h3"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {images[2].title}
              <div className={classes.imageMarked} />
            </Typography>
          </div>
        </ButtonBase>
      </div>
      <HandleDialogs open={open} handleClose={handleClose} value={value} />
    </Container>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
